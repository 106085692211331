<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root elements -->
    <div style="height: inherit">
        <div
            class="body-content-overlay"
            :class="{'show': mqShallShowLeftSidebar}"
            @click="mqShallShowLeftSidebar = false"
        />

        <!-- Email List -->
        <div class="email-app-list">

            <!-- App Searchbar Header -->
            <div
                v-if="false"
                class="app-fixed-search d-flex align-items-center">

                <!-- Toggler -->
                <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                        class="cursor-pointer"
                        @click="mqShallShowLeftSidebar = true"
                    />
                </div>


            </div>

            <!-- Emails List -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="email-user-list scroll-area h-100"
            >
                <ul class="email-media-list">
                    <b-media
                        v-for="email in topMessages"
                        :key="email.id"
                        tag="li"
                        no-body
                        :class="{ 'mail-read': email.isRead }"
                        @click="updateEmailViewData(email)"
                    >

                        <b-media-aside class="media-left mr-50">
                            <b-avatar
                                class="avatar badge-light-primary"
                                size="40"
                                variant="primary"
                                :src="email.User.ImageUrl"
                                :text="(email.User.FirstName ? email.User.FirstName.charAt(0) : '') + (email.User.LastName ? email.User.LastName.charAt(0) : '')"
                            />
                        </b-media-aside>

                        <b-media-body class="sk-message-holder" @click="toggleRead(email)">

                            <b-row>
                                <b-col cols="9">
                                    <div class="main-mail-content">
                                        <div class="mail-items">
                                            <h5 class="mb-25" :class="!email.Opened && email.Sender !== currentUserId ? 'sk-mail-unread':'' ">
                                                {{ email.Label }}
                                            </h5>
                                            <p class="text-truncate text-muted">
                                                {{ email.User.Label }}
                                            </p>
                                        </div>
                                    </div>
                                </b-col>

                                <b-col cols="3" class="text-right">
                                    <div class="sk-mail-meta-content d-flex justify-content-end">
                                        <div class="mail-meta-item d-inline-flex align-items-right mr-2 text-right">
                                            <small class="mail-date-time text-muted">{{ email.CreatedAt | date }}<br/>
                                                <font-awesome-icon
                                                    v-if="email.Sender === currentUserId"
                                                    :class="email.Opened ? `text-primary` : ''"
                                                    icon="check-double" /> {{ email.CreatedAt | time }}</small>
                                        </div>
                                        <div class="mail-soft d-inline-flex"
                                             :class="softClass(email)"
                                        >
                                            {{softwareShort(email.SoftwareId)}}
                                        </div>


                                        <div class="action-icons ml-05">
                                            <font-awesome-icon :icon="['fas', 'sync-alt']"
                                                v-if="usingArchive"
                                                @click.stop="restoreMessage(email)"
                                                size="16"
                                                v-b-tooltip.hover="i18nT(`Restore message`)"
                                            />

                                            <feather-icon
                                                v-else
                                                @click.stop="archiveMessage(email)"
                                                icon="TrashIcon"
                                                size="16"
                                                v-b-tooltip.hover="i18nT(`Archive message`)"
                                            />
                                        </div>

                                    </div>

                                </b-col>

                            </b-row>

                            <b-row>
                                <b-col>
                                    <div class="main-mail-content">
                                        <div class="mail-message">
                                            <span class="text-truncate mb-0 sk-msg-preview" :class="!email.Opened && email.Sender !== currentUserId ? 'sk-mail-unread':'' " >{{ filterTags(email.Message) }}</span>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                        </b-media-body>
                    </b-media>
                </ul>
                <div
                    class="no-results"
                    :class="{'show': !emails.length}"
                >
                    <h5>{{i18nT(`No items found`)}}</h5>
                </div>
            </vue-perfect-scrollbar>
        </div>

        <!-- Email View/Detail -->
        <email-view
            :class="{'show': showEmailDetails}"

            :opended-email-meta="opendedEmailMeta"
            @close-email-view="returingToList"
            @move-email-to-folder="moveOpenEmailToFolder"
            @toggle-email-starred="toggleStarred(emailViewData)"
            @update-email-label="updateOpenEmailLabel"
            @mark-email-unread="markOpenEmailAsUnread"
            @change-opened-email="changeOpenedEmail"
            :onReply="onReply"
        />

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <email-left-sidebar
                :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
                :emails-meta="emailsMeta"
                :class="{'show': mqShallShowLeftSidebar}"
                @close-left-sidebar="mqShallShowLeftSidebar = false"
                :onNewMsg="onReply"
                @softs="onChangeInboxes"
            />
        </portal>

        <!-- Compose Email Modal -->
        <email-compose
            v-model="shallShowEmailComposeModal"
            :refresh="fetchEmails"


            :messageParent="parentMessageId"
        />
    </div>
</template>

<script>
import store from '@/store'
import {
    ref, onUnmounted, computed, watch,
    // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    VBTooltip,
    // BDropdown,
    // BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {filterTags, formatDateToMonthShort} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import EmailLeftSidebar from './EmailLeftSidebar.vue'
import EmailView from './EmailView.vue'
import emailStoreModule from './emailStoreModule'
import useEmail from './useEmail'
import EmailCompose from './EmailCompose.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth'

import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    components: {
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        // BDropdown,
        // BDropdownItem,

        // 3rd Party
        VuePerfectScrollbar,

        // App SFC
        EmailLeftSidebar,
        EmailView,
        EmailCompose,
    },
    created() {
        if (router.currentRoute.params.id) {
            this.shallShowEmailComposeModal = true
            this.recipientId = router.currentRoute.params.id
            store.commit('messages/SET_RECIPIENT_ID', this.recipientId);
        }
        if (router.currentRoute.name === 'inbox-new') {
            this.shallShowEmailComposeModal = true
            this.recipientId = null
        }
        if(router.currentRoute.name == 'archive-all') {
            this.shallShowEmailComposeModal = false
            this.usingArchive = true,
            this.fetchEmails(this.currentSofts);
        }

    },
    setup() {
        let selectedSoftwares = [1, 2, 3, 4, 5];
        const EMAIL_APP_STORE_MODULE_NAME = 'app-email'

        // Register module
        if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
        })

        const {route, router} = useRouter()
        const {resolveLabelColor} = useEmail()

        // Route Params
        const routeParams = computed(() => route.value.params)
        watch(routeParams, () => {
            // eslint-disable-next-line no-use-before-define
            fetchEmails()
        })

        // Emails & EmailsMeta
        const emails = ref([])
        const emailsMeta = ref({})

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        }

        // Search Query
        const routeQuery = computed(() => route.value.query.q)
        const searchQuery = ref(routeQuery.value)
        watch(routeQuery, val => {
            searchQuery.value = val
        })
        // eslint-disable-next-line no-use-before-define
        watch(searchQuery, () => fetchEmails())
        const updateRouteQuery = val => {
            const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

            if (val) currentRouteQuery.q = val
            else delete currentRouteQuery.q

            router.replace({name: route.name, query: currentRouteQuery})
        }

        const fetchEmails = (selectSoft) => {

            if(!selectSoft){
                selectSoft = selectedSoftwares;
            }
            store.dispatch('messages/fetchEmails', {
                q: searchQuery.value,
                folder: router.currentRoute.params.folder || 'inbox',
                label: router.currentRoute.params.label,
                softwares: selectSoft,
                usingArchive: router.currentRoute.name === 'archive-all'
            })
                .then(response => {
                    console.log("Fetch emails: ", response.data.emails);
                    emails.value = response.data.emails
                    emailsMeta.value = response.data.emailsMeta

                    const msgid = router.currentRoute.params.msgid
                    const msg = response.data.emails.find(e => e.Id == msgid)
                    if (msg) {
                        emailViewData.value = msg
                        showEmailDetails.value = true
                    }
                })
        }


        // ------------------------------------------------
        // Mail Selection
        // ------------------------------------------------
        const selectedEmails = ref([])
        const toggleSelectedMail = mailId => {
            const mailIndex = selectedEmails.value.indexOf(mailId)

            if (mailIndex === -1) selectedEmails.value.push(mailId)
            else selectedEmails.value.splice(mailIndex, 1)
        }
        const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
        const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
        const selectAllCheckboxUpdate = val => {
            selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
        }
        // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

        // ------------------------------------------------
        // Mail Actions
        // ------------------------------------------------
        const toggleStarred = email => {
            store.dispatch('app-email/updateEmail', {
                emailIds: [email.id],
                dataToUpdate: {isStarred: !email.isStarred},
            }).then(() => {
                // eslint-disable-next-line no-param-reassign
                email.isStarred = !email.isStarred
            })
        }

        const moveSelectedEmailsToFolder = folder => {
            store.dispatch('app-email/updateEmail', {
                emailIds: selectedEmails.value,
                dataToUpdate: {folder},
            })
                .then(() => {
                    fetchEmails()
                })
                .finally(() => {
                    selectedEmails.value = []
                })
        }

        const updateSelectedEmailsLabel = label => {
            store.dispatch('app-email/updateEmailLabels', {
                emailIds: selectedEmails.value,
                label,
            })
                .then(() => {
                    fetchEmails()
                })
                .finally(() => {
                    selectedEmails.value = []
                })
        }

        const markSelectedEmailsAsUnread = () => {
            store.dispatch('app-email/updateEmail', {
                emailIds: selectedEmails.value,
                dataToUpdate: {isRead: false},
            })
                .then(() => {
                    fetchEmails()
                })
                .finally(() => {
                    selectedEmails.value = []
                })
        }

        // ------------------------------------------------
        // Email Details
        // ------------------------------------------------
        const showEmailDetails = ref(false)
        const emailViewData = ref({})
        const opendedEmailMeta = computed(() => {
            const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
            return {
                hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
                hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
            }
        })
        const updateEmailViewData = email => {
            store.commit('messages/SET_CURRENT_MESSAGE', email)
            emailViewData.value = email
            showEmailDetails.value = true
        }
        const moveOpenEmailToFolder = folder => {
            selectedEmails.value = [emailViewData.value.id]
            moveSelectedEmailsToFolder(folder)
            selectedEmails.value = []
            showEmailDetails.value = false
        }
        const updateOpenEmailLabel = label => {
            selectedEmails.value = [emailViewData.value.id]
            updateSelectedEmailsLabel(label)

            // Update label in opened email
            const labelIndex = emailViewData.value.labels.indexOf(label)
            if (labelIndex === -1) emailViewData.value.labels.push(label)
            else emailViewData.value.labels.splice(labelIndex, 1)

            selectedEmails.value = []
        }

        const markOpenEmailAsUnread = () => {
            selectedEmails.value = [emailViewData.value.id]
            markSelectedEmailsAsUnread()

            selectedEmails.value = []
            showEmailDetails.value = false
        }

        const changeOpenedEmail = dir => {
            const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
            const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
            emailViewData.value = emails.value[newEmailIndex]
        }

        // * If someone clicks on filter while viewing detail => Close the email detail view
        watch(routeParams, () => {
            showEmailDetails.value = false
        })

        // * Watcher to reset selectedEmails
        // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
        // eslint-disable-next-line no-use-before-define

        // Compose
        const shallShowEmailComposeModal = ref(false)

        // Left Sidebar Responsiveness
        const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

        watch([emails, routeParams], () => {

        });

        return {
            // UI
            perfectScrollbarSettings,

            // Emails & EmailsMeta
            emails,
            emailsMeta,

            // Mail Selection
            selectAllEmailCheckbox,
            isSelectAllEmailCheckboxIndeterminate,
            selectedEmails,
            toggleSelectedMail,
            selectAllCheckboxUpdate,

            // Mail Actions
            toggleStarred,
            moveSelectedEmailsToFolder,
            updateSelectedEmailsLabel,
            markSelectedEmailsAsUnread,

            // Email Details
            showEmailDetails,
            emailViewData,
            opendedEmailMeta,
            updateEmailViewData,
            moveOpenEmailToFolder,
            updateOpenEmailLabel,
            markOpenEmailAsUnread,
            changeOpenedEmail,

            // Search Query
            searchQuery,
            updateRouteQuery,

            // UI Filters
            filterTags,
            formatDateToMonthShort,

            // useEmail
            resolveLabelColor,

            // Compose
            shallShowEmailComposeModal,

            // Left Sidebar Responsiveness
            mqShallShowLeftSidebar,
            fetchEmails,
        }
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            recipientId: 0,
            parentMessageId: null,
            currentSofts: [1, 2, 3, 4, 5],
            mailSubject: '',
            usingArchive: false,

        }
    },
    mounted() {
        let curSoftId = useAuth.getCurrentSoftwareId();
        if(curSoftId) {
            this.currentSofts = [curSoftId];
        }

        this.fetchEmails(this.currentSofts);
    },
    methods: {
        restoreMessage(email){
           this.$http.post('messages/archive?message_ids='+email.parentThread.Id+'&remove=1').then((resp) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(resp.data.message),
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.fetchEmails(this.currentSofts);
            })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(error.message),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                });
        },
        archiveMessage(email){
            this.$http.post('messages/archive?message_ids='+email.parentThread.Id)
                .then((resp) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(resp.data.message),
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.fetchEmails(this.currentSofts);
                })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(error.message),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                });
        },
        returingToList(){
            this.fetchEmails(this.currentSofts);
            this.showEmailDetails = false
        },

        softClass(email){
            let classString = 'soft-tag-'+(email.SoftwareId-1);
            if(!email.Opened && email.Sender != useAuth.getProperUserId()) {
                classString += ' sk-mail-unread';
            }
            return classString;
        },
        latestMessage(replies) {
            if(replies.length > 0) {
                return replies[0].CreatedAt;
            }
        },
        toggleRead(email) {
            let self = this;
            let parentThreadId = email.parentThread.Id;

            return this.$http.post('messages/markAsRead?message_ids='+parentThreadId)
                .then(() => {
                    self.fetchEmails(self.currentSofts);
                    email.Opened = 1;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        softwareShort(dictId){
          let softDict = [
              'Re',
              'On',
              'Hr',
              'En',
              'Ac',
          ];

          let userType = useAuth.getUserType();

          if(userType == 'Freelancer') {
                softDict = [
                    'Ca',
                    'Em',
                    'Em',
                    'Em',
                    'Em',
                ];
          }

          return softDict[dictId-1];
        },

        onReply(message) {
            //store.commit('messages/SET_CURRENT_MESSAGE', message);
            this.recipientId = null;
            if(message){
                store.commit('messages/SET_IS_REPLY', true);

                if(message.Sender === this.currentUserId) {
                    this.recipientId = message.Recipient;
                }else {
                    this.recipientId = message.Sender;
                }

                if(message && message.ParentId){
                    this.parentMessageId = message.ParentId;
                }
                else {
                    this.parentMessageId = message.Id;
                }

            } else {
                store.commit('messages/SET_IS_REPLY', false);
                this.parentMessageId = null;
            }

            this.shallShowEmailComposeModal = true;
            store.commit('messages/SET_RECIPIENT_ID', this.recipientId);

        },
        onChangeInboxes(softs) {
            this.currentSofts = softs;
            this.fetchEmails(softs);
        },
    },
    computed: {
        topMessages() {
            return store.getters['messages/getTopMessages']
        },
        currentUserId() {
            return useAuth.getProperUserId();
        },
        mailRecipientId() {
            let curUserId = useAuth.getProperUserId();
            if (this.recipientId && this.recipientId !== curUserId) {
                return this.recipientId
            } else {
                return this.senderId
            }
        },
        currentMessage() {
            return store.getters['messages/currentMessage']
        },

    }

}
</script>

<style lang="scss" scoped>
.action-icons {
    margin-top: -2px;
}

.sk-msg-preview {
    opacity: 0.7;
}

.sk-mail-unread, .sk-mail-unread h5 {
    font-weight: 700;
    opacity: 1;
}

 .sk-message-holder.media-body {
    //display: flex;
    //justify-content: space-between;
}

.mail-soft {
    background: #999999;
    color: white;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;
}

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
