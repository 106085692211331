<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{i18nT(`New message`)}}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail(recipientId)"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          @click="sendEmail"
        >
        {{i18nT(`Send`)}}
        </b-button>
      </div>
    </template>
    <validation-observer ref="simpleRules">
      <b-form>
        <validation-provider
            #default="{ errors }"
            :name="i18nT(`To`)"
            rules="required"
        >
          <!-- Field: To -->
          <div class="compose-mail-form-field">
            <label
              for="email-to"
              class="form-label mr-1"
            >{{i18nT(`To`)}}: </label>

              <v-select
                v-model="recipientGuy"
                label="name"
                class="flex-grow-1 email-to-selector"
                :options="emailToOptions"
                input-id="email-to"
                :disabled="isReply"
              >
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>

        <!-- Field: Cc (Hidden Initially) -->
        <!-- Field: Subject -->
        <validation-provider
            #default="{ errors }"
            :name="i18nT(`Subject`)"
            rules="required"
        >
          <div class="compose-mail-form-field">
            <label for="email-subject">{{i18nT(`Subject`)}}: </label>
            <b-form-input
              id="email-subject"
              v-model="localSubject"
              :state="errors.length > 0 ? false:null"
            />            
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>


        <!-- Field: Message - Quill Editor -->
        <validation-provider
            #default="{ errors }"
            name="Message"
            rules="required"
        >
          <div class="message-editor">
            <quill-editor
              id="quil-content"
              v-model="composeData.message"
              :options="editorOption"
            />
            
            <small class="text-danger ml-2">{{ errors[0] }}</small>
          </div>
        </validation-provider>
      </b-form>
    </validation-observer>

    <!-- Modal: Body -->
    

  </b-modal>
</template>

<script>
import {
    BForm,
    BFormInput,
    BAvatar,
    BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import useAuth from '@/auth/useAuth';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BForm,
    BFormInput,
    BAvatar,
    BButton,

    // 3rd Party
    quillEditor,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
      props: {
        shallShowEmailComposeModal: {
          type: Boolean,
          required: true,
        },
        refresh: {
          type: Function,
          required: true
        },
        messageParent: {
            type: Number,
            required: false
        }
      },

    setup(_, { emit }) {
    const composeData = ref({})
    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
        theme: 'snow',
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean'],
          ]
        },
      }

    const discardEmail = (recipientId) => {

        if(!recipientId){
            composeData.value.message = ""
            emit('update:shall-show-email-compose-modal', false)
        }
    }

    return {
      composeData,
      editorOption,
      showCcField,
      showBccField,

      // Email actions
      discardEmail,
    }
    },
    created () {
        this.$http.get(
            `messages/recipients`
        ).then(({data}) => {
            this.emailToOptions = data.data.map((recipient) => ({
                avatar: recipient.image,
                name: recipient.label,
                id: recipient.id
            }))


            if(this.recipientId){
                this.recipientGuy = this.emailToOptions.find(recipient => {
                    return recipient.id == this.recipientId
                });
            }

            //
            // this.composeData.to = this.emailToOptions.filter(recipient => {
            //     return recipient.id === this.recipientId
            // })
        })
    },
    data() {
    return {
      recipientGuy: null,
      localSubject: '',
      emailToOptions: [],
      required,
      email
    }
    },
    methods: {
        sendEmail () {
            this.$refs.simpleRules.validate().then(success => {
                if(success) {
                    const curWorkspace = useAuth.getCurWorkspace();

                    const formData = new FormData()
                    formData.append('Recipient', this.recipientGuy.id)
                    formData.append('Label', this.localSubject)
                    formData.append('Message', this.composeData.message)
                    formData.append('company_id', curWorkspace.Id)
                    if(this.composeData.parent) {
                        formData.append('ParentId', this.composeData.parent)
                    }

                    this.$http.post(
                        'messages', formData
                    ).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })
                        this.refresh()
                        const currentMessage = this.$store.getters['messages/currentMessage']
                        currentMessage.Replies = res.data.data
                        if(currentMessage.ParentId === null && this.composeData.parent !== null) {
                          currentMessage.ParentId = this.composeData.parent
                        }
                        this.$store.commit('messages/SET_CURRENT_MESSAGE', currentMessage)
                        this.discardEmail();
                        if(this.$route.query.newMsg) {
                          this.$router.replace({ query: {} })
                        }
                    })
                }
            })
        },
        defineSubject(isReplying){
            if(isReplying)  {
                let curMessage = store.getters['messages/currentMessage'];
                if(curMessage.Label.indexOf('Re:') === -1) {
                    this.localSubject = `Re: ${curMessage.Label}`;
                }else {
                    this.localSubject = curMessage.Label
                }
            }else {
                this.localSubject = "";
            }
        }
    },
    computed: {
        isReply () {
            return store.getters['messages/getIsReply']
        },
        recipientId() {
            return store.getters['messages/getRecipientId']
        }
    },
    watch: {
      isReply (replying) {
          this.defineSubject(replying);
      },
        messageParent: {
            immediate: true,
            handler: function (val) {
                this.composeData.parent = val
            }
        },
        recipientId: function(newVal) {
            if(newVal) {
                this.recipientGuy = this.emailToOptions.find(recipient => {
                    return recipient.id == newVal
                });
                this.defineSubject(this.isReply);
            }else {
                this.recipientGuy = null;
            }
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
