var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Compose Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.i18nT("New message"))+" ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":function($event){return _vm.$emit('update:shall-show-email-compose-modal', false)}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.discardEmail(_vm.recipientId)}}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","right":""},on:{"click":_vm.sendEmail}},[_vm._v(" "+_vm._s(_vm.i18nT("Send"))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.i18nT("To"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label mr-1",attrs:{"for":"email-to"}},[_vm._v(_vm._s(_vm.i18nT("To"))+": ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"label":"name","options":_vm.emailToOptions,"input-id":"email-to","disabled":_vm.isReply},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.recipientGuy),callback:function ($$v) {_vm.recipientGuy=$$v},expression:"recipientGuy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":_vm.i18nT("Subject"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-subject"}},[_vm._v(_vm._s(_vm.i18nT("Subject"))+": ")]),_c('b-form-input',{attrs:{"id":"email-subject","state":errors.length > 0 ? false:null},model:{value:(_vm.localSubject),callback:function ($$v) {_vm.localSubject=$$v},expression:"localSubject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"message-editor"},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),_c('small',{staticClass:"text-danger ml-2"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }