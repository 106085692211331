<template>
  <b-card no-body>
    <b-card-header
      v-if="message && message.SenderObj"
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          size="48"
          :src="message.SenderObj.ImageUrl"
          :text="(message.SenderObj.FirstName ? message.SenderObj.FirstName.charAt(0) : '') + (message.SenderObj.LastName ? message.SenderObj.LastName.charAt(0) : '')"
          class="mr-75 badge-light-primary"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.SenderObj.Label }}
          </h5>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span class="font-small-3 text-muted mr-25">{{ message.SenderObj.Email }}</span>
              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">
                    {{i18nT(`From`)}}:
                  </td>
                  <td>{{ message.SenderObj.Email }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                     {{i18nT(`To`)}}:
                  </td>
                  <td>{{ message.RecipientObj.Email }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                      {{i18nT(`Date`)}}:
                  </td>
                  <td>{{message.CreatedAt | date}}</td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-right text-right">
        <small class="mail-date-time text-muted">{{ message.CreatedAt | date }}<br/>
            <font-awesome-icon
                v-if="message.Sender === currentUserId"
                v-b-tooltip="message.Opened ? i18nT('Message has been read by the recipient') : i18nT('Message has not been read yet by the recipient')"
                icon="check-double"
                :class="message.Opened ? 'text-primary' : ''" />
            {{ message.CreatedAt | time }}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.Message"
      />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Attachment{{ message.attachments.length > 1 ? 's' : '' }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.url"
            target="_blank"
            :class="{'mt-75': index}"
          >
            <b-img
              :src="attachment.thumbnail"
              width="16px"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.fileName }}</span>
            <span class="text-muted font-small-2 ml-25">({{ attachment.size }})</span>
          </b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BDropdown,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
    VBTooltip
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import useAuth from "@/auth/useAuth";

export default {
  components: {
    BDropdown,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
  },
    directives: {
        'b-tooltip': VBTooltip
    },
    computed: {
        currentUserId() {
            let yop = useAuth.getProperUserId();
            console.log(yop);
            return yop;
        },
    },
  props: {
    message: {
      type: Object,
      required: true,
    },
    onReply: {
      type: Function,
      required: true
    }
  },
    mounted() {
        console.log("Tread message: ", this.message);
    },
    setup() {
    return {
      formatDate,
    }
  },
}
</script>

<style>

</style>
