import axios from '@axios';
import useAuth from '@/auth/useAuth';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmails(ctx, payload) {
      let messageBase = `messages?`;
      if(payload && payload.folder){
        messageBase += `outbox=${payload.folder === 'sent' ? 1 : 0}`;
      }

      if(payload.softwares){
        messageBase += `&softwares=`+ payload.softwares.join(',');
      }
      return new Promise((resolve, reject) => {
        axios
          .get(messageBase)
          .then(({ data }) => {

            if(data.data.length === 0){
              resolve({
                data: {
                  emails: [],
                }
              });
            } else {
              resolve({
                data: {
                  emails: data.data.map((email) => {
                    if (payload.folder === 'sent') {
                      email.User = email.RecipientObj;
                    } else {
                      email.User = email.SenderObj;
                    }
                    return email;
                  }),
                },
              });
            }
          });

        // axios
        //   .get('/apps/email/emails', { params: payload })
        //   .then((response) => resolve(response))
        //   .catch((error) => reject(error));
      });
    },
    updateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails', payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails-label', payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/email/paginate-email', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
