<template>
    <div class="sidebar-left">
        <div class="sidebar">
            <div class="sidebar-content email-app-sidebar">
                <div class="email-app-menu">
                    <div class="form-group form-group-compose p-2 pb-0 mb-0">
                        <div class="d-flex align-content-lg-between mb-2">
                            <h4 class="mr-2">{{ i18nT(`Select your mailbox`) }}</h4>

                            <span class="cursor-pointer text-primary" @click="reloadMsgs('inbox')">
                        <font-awesome-icon :icon="['fas', 'sync-alt']" :spin="reloading"/>
                    </span>
                        </div>


                        <b-form-group>
                            <b-form-checkbox-group
                                v-model="selectedInboxes"
                                v-on:change="onChangeInboxes"
                                name="event-filter"
                                stacked
                            >
                                <b-form-checkbox
                                    v-for="item in softwares"
                                    :key="item.short"
                                    name="event-filter"
                                    :value="item.id"
                                    class="mb-1"
                                    :class="`checkbox-${item.color}`"
                                >
                                    {{ i18nT(item.title) }}

                                    <span
                                        v-if="item.unread"
                                        class="sk-lined-up-badge badge badge-up badge-pill bg-danger mt-05">
                                        {{ item.unread}}
                                    </span>
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>


                    <div class="form-group-compose text-center compose-btn pt-0">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            @click="onNewMsg(null)"
                        >
                            <feather-icon icon="PlusCircleIcon"/>
                            {{ i18nT(`New message`) }}
                        </b-button>
                    </div>
                    <vue-perfect-scrollbar
                        :settings="perfectScrollbarSettings"
                        class="sidebar-menu-list scroll-area"
                    >
                        <!-- Filters -->
                        <b-list-group
                            class="list-group-messages">
                            <b-list-group-item
                                v-for="filter in emailFilters"
                                :key="filter.title + $route.path"
                                :to="filter.route"
                                :active="isRouteActive(filter.route)"
                                @click="$emit('close-left-sidebar')"
                            >
                                <feather-icon :icon="filter.icon" size="18" class="mr-75"/>
                                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                            </b-list-group-item>
                        </b-list-group>
                    </vue-perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
    BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
} from "bootstrap-vue";
import {isDynamicRouteActive} from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import router from "@/router";

import {useUtils as useI18Utils} from "@core/libs/i18n";
//import _ from "lodash";
import useAuth from "@/auth/useAuth";
import store from "@/store";
import _ from "lodash";

const {i18n} = useI18Utils();

export default {
    directives: {
        Ripple
    },
    components: {
        // BSV
        BButton,
        BListGroup,
        BListGroupItem,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormGroup,
        // BBadge,

        // 3rd Party
        VuePerfectScrollbar
    },
    props: {
        shallShowEmailComposeModal: {
            type: Boolean,
            required: true
        },
        onNewMsg: {
            type: Function,
            required: true
        }
    },

    mounted() {
        if (this.$route.query.newMsg) {
            this.onNewMsg();
        }
        let curSoftId = useAuth.getCurrentSoftwareId();
        if (curSoftId) {
            this.selectedInboxes = [curSoftId];
        }

        let preferredOrder = ['ac', 'hr', 're', 'on', 'en'];

        this.softwares = Object.values(useAuth.getSoftwares());
        console.log("This softwares", this.softwares);
        this.softwares.sort((a, b) => {
            let indexOfA = preferredOrder.indexOf(a.short);
            let indexOfB = preferredOrder.indexOf(b.short);

            // If not found in the preferredOrder, put it to the end
            if (indexOfA === -1) indexOfA = Number.MAX_VALUE;
            if (indexOfB === -1) indexOfB = Number.MAX_VALUE;

            return indexOfA - indexOfB;
        });

        for(let i in this.softwares) {
            this.softwares[i].unread = 0;
        }
    },
    data() {
        return {
            selectedInboxes: [1, 2, 3, 4, 5],
            reloading: false,
            softwares: [],
        }
    },
    methods: {
        onChangeInboxes(value) {
            this.selectedInboxes = value;
            this.$emit('softs', this.selectedInboxes);
        },
        reloadMsgs() {
            let self = this;
            this.$emit('softs', this.selectedInboxes);
            this.reloading = true;
            setTimeout(() => {
                self.reloading = false;
            }, 1000);
        }
    },
    computed: {
        mailBoxUnreadCounts() {
            let mailboxes = this.$store.state.app.mailboxCounts;
            return mailboxes;
        },
    },

    setup() {
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60
        };

        const emailFilters = [
            {
                title: i18n(`My messages`),
                icon: "MailIcon",
                route: {name: "inbox-all"}
            },
            {
                title: i18n(`Archive`),
                icon: "TrashIcon",
                route: {name: "archive-all"}
            },
            // {
            //   title: i18n(`Sent`),
            //   icon: "SendIcon",
            //   route: { name: "inbox-folder", params: { folder: "sent" } }
            // }
        ];

        const emailLabel = [
            {
                title: i18n(`Personal`),
                color: "success",
                route: {name: "apps-email-label", params: {label: "personal"}}
            },
            {
                title: i18n(`Company`),
                color: "primary",
                route: {name: "apps-email-label", params: {label: "company"}}
            },
            {
                title: i18n(`Important`),
                color: "warning",
                route: {name: "apps-email-label", params: {label: "important"}}
            },
            {
                title: i18n(`Private`),
                color: "danger",
                route: {name: "apps-email-label", params: {label: "private"}}
            }
        ];

        const resolveFilterBadgeColor = filter => {
            if (filter === "Draft") return "light-warning";
            if (filter === "Spam") return "light-danger";
            return "light-primary";
        };

        const isRouteActive = route =>
            (router.currentRoute.name == "inbox-message" && route.name == "inbox") ||
            (router.currentRoute.name == "inbox-new" && route.name == "inbox") ||
            isDynamicRouteActive(route);



        /*
        let softwares = _.values(useAuth.getSoftwares()).map(software => ({
            text: software.short.charAt(0).toUpperCase() + software.short.slice(1),
            value: software.short
        }))

         */

        const currentSoftware = useAuth.getCurrentSoftware()

        return {
            // UI
            perfectScrollbarSettings,
            isDynamicRouteActive,
            resolveFilterBadgeColor,
            isRouteActive,
            // Filter & Labels
            emailFilters,
            emailLabel,
            currentSoftware
        };
    },

    watch: {
        mailBoxUnreadCounts: {
            deep: true,
            handler: function (newCounts) {
                let self = this;
                if (newCounts && newCounts.length > 0) {
                    
                    newCounts.forEach((count) => {
                        self.softwares.find(software => software.id === count.software_id).unread = count.count
                    })
                    
                    self.softwares = [...self.softwares];
                }
            }
        }
    }
};
</script>

<style>

.sk-lined-up-badge {
    top: -5px !important;
}

</style>
