<template>
    <div class="email-app-details">

        <!-- Email Header -->
        <div class="email-detail-header">

            <!-- Header: Left -->
            <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
              :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
              size="20"
              class="align-bottom"
              @click="$emit('close-email-view')"
          />
        </span>
                <h4 class="email-subject mb-0">
                    {{ emailViewData.Label }}
                </h4>

            </div>
            <div class="right-side">
                <div class="reply-container mr-2">
                    <b-button
                        size="sm"
                        variant="primary"
                        right
                        :disabled="emailViewData.ReplyAvailable !== 1"
                        @click="onReply(emailViewData)"
                    >
                        <feather-icon icon="RotateCwIcon" size="14" class="align-middle mr-25"></feather-icon>

                        {{ i18nT(`Reply`) }}
                    </b-button>
                </div>
                <div v-if="emailViewData.SoftwareId" class="mail-soft">
                    {{ softwareShort(emailViewData.SoftwareId) }}
                </div>
            </div>

        </div>


        <!-- Email Details -->
        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="email-scroll-area scroll-area"
        >

            <!-- Label Row -->
            <b-row>
                <b-col cols="12">
                    <div class="email-label">
                        <b-badge
                            v-for="(label) in emailViewData.labels"
                            :key="label"
                            pill
                            class="text-capitalize mr-50"
                            :variant="`light-${resolveLabelColor(label)}`"
                        >
                            {{ label }}
                        </b-badge>
                    </div>
                </b-col>
            </b-row>

            <!-- Email Thread -->
            <b-row>
                <b-col cols="12" v-for="oneEmail in emailViewData.Replies" :key="oneEmail.Id">
                    <email-message-card :message="oneEmail" :onReply="onReply"/>
                </b-col>
            </b-row>
        </vue-perfect-scrollbar>
    </div>
</template>

<script>
import {
    //BDropdown,
    //BDropdownItem,
    BRow,
    BCol,
    BBadge,
    //BCard,
    BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useEmail from './useEmail'
import EmailMessageCard from './EmailMessageCard.vue'
import store from '@/store/index'

export default {
    components: {
        // BSV
        // BDropdown,
        // BDropdownItem,
        BRow,
        BCol,
        BBadge,
        BButton,

        // 3rd Party
        VuePerfectScrollbar,

        // SFC
        EmailMessageCard,
    },
    props: {
        opendedEmailMeta: {
            type: Object,
            required: true,
        },
        onReply: {
            type: Function,
            required: true
        }
    },
    setup() {
        const {resolveLabelColor} = useEmail()

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        }

        return {

            // UI
            perfectScrollbarSettings,

            // useEmail
            resolveLabelColor,
        }
    },
    computed: {
        emailViewData() {
            return store.getters['messages/currentMessage']
        },
    },
    methods: {
        softwareShort(dictId) {
            if (dictId) {
                let softDict = [
                    '',
                    'Re',
                    'On',
                    'Hr',
                    'En',
                    'Ac',
                ];

                return softDict[dictId-1];
            } else {
                return '';
            }
        },
    }
}
</script>

<style>

.reply-container {
    display: inline-flex;
}

.mail-soft {
    background: #999999;
    color: white;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding-top: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;
}

</style>
